import Bugsnag from "@bugsnag/js";

if (process.env.BUGSNAG_API_KEY) {
  Bugsnag.start({
    apiKey: process.env.BUGSNAG_API_KEY,
    collectUserIp: false,
    releaseStage: process.env.RELEASE_STAGE || process.env.NODE_ENV,
  });
}

export const setUser = (id: string | undefined) =>
  Bugsnag.isStarted() && Bugsnag.setUser(id);

window["Bugsnag"] = Bugsnag;
